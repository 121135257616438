.nav-container {
    height: 36px;
}

.burger-container {
    position: absolute;
    width: 36px;
    height: 36px;
    /* left: 30px; */
    left: 2.5rem;
    top: 2rem;
}

.avatar-container {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 2rem;
    top: 1.8rem;
}

.back-container {
    position: absolute;
    width: 36px;
    height: 36px;
    /* left: 30px; */
    left: 1.5rem;
    top: 2rem;
}

.dropdown-fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.dropdown-fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}
  
.dropdown-content {
    position: relative;
    background-color: #fdfdfd;
    top: 2rem;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    max-width: 300px;
}

.content-box {
    height: 300px;
    padding: 20px;
    text-align: center;
}
  