.main{
    font-family: Inter, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle{
    /* font-size: 1.75rem; */
    /* font-weight: bold; */
}

.buttonRow{
    display: flex;
    align-items:baseline;
    width: 70%;
    justify-content: space-between;
}

.buttonRow > *{
    width: 33%;
}

.subtitle{
    margin: 20px 0 20px;
    font-size: 30px;
    font-family: 'Nunito';
    transform: translateY(-4px)
}

.listItems {
    /* border: 1px solid pink; */
    padding: 1rem;
    width: 100%;
    text-align: left;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateX(-10px);
}
.icon {
    /* align-items: flex-start; */
    padding-right: 10px;
}