.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.pageTitle {
	font-size: 1.75rem;
	font-weight: bold;
}

.recordItems {
	text-align: left;
	/* border: 1px solid pink; */
	padding: 1rem;
	margin-top: 1rem;
	width: 100%;
}
