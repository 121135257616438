.KVItemBox{
    /* text-align: center; */
    font-family: Inter, sans-serif;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content:baseline;

    /* border: 1px solid black; */
}

.KVItemBox > * {
    text-align: left;
    font-size: .75rem;
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid pink; */
    margin: 0;
}

.keyName{
    color: lightslategray;
    /* align-self: flex-start; */
}

.value{
    font-weight: bold;
}