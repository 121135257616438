input {
	outline: none;
}

.wrapper {
	max-width: 500px;
	background: #fff;
	padding: 1rem;
}

.wrapper .input-data {
	height: 40px;
	width: 100%;
	position: relative;
}

.wrapper .input-data input {
	height: 100%;
	width: 100%;
	border: none;
	font-size: 17px;
	border-bottom: 2px solid silver;
}

.input-data input:focus ~ label,
.input-data input:not(:placeholder-shown) ~ label {
	transform: translateX(100px);
	font-size: 15px;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
}

.wrapper .input-data label {
	position: absolute;
	bottom: 4px;
	left: 0;
	color: grey;
	pointer-events: none;
	visibility: visible;
	opacity: 1;
	transition: all 0.3s ease;
}

.input-data .underline {
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	text-align: left;
}

.input-data .underline:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: #0d5eaf;
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 300ms ease;
}

.input-data input:focus ~ .underline:before,
.input-data input:not(:placeholder-shown) ~ .underline:before {
	transform: scaleX(1);
}

/* Hacky but needed */
input::placeholder {
	color: white;
}
