.button {
	text-align: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	/* width: 5rem; */
	transition: all 100ms linear;
}
.button:hover {
	cursor: pointer;
	transform: scale(0.95);
	transition: all 200ms linear;
}

.svg {
	width: 35px;
}

.labelText {
	font-family: Inter, sans-serif;
	font-weight: bold;
	font-size: x-small;
	display: table-caption;
	/* line-height: 1.2rem; */
	margin: 0;
	padding-top: 0.5rem;
}
