.items {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.items > * {
	box-sizing: border-box;
	/* border: 1px solid pink; */
}

.avatar {
	width: 10%;
	align-self: flex-start;
	text-align: center;
}

.avatar img {
	width: 100%;
	max-width: 2rem;
	border-radius: 100%;
}

.content {
	width: 80%;
	padding: 0 1rem;
}

.title {
	font-family: Nunito, sans-serif;
	font-weight: bold;
	font-size: 0.7rem;
}

.updated {
	color: #888;
	font-size: 0.7rem;
	margin: 0;
}

.button {
	width: 10%;
}
