.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.pageTitle {
	/* font-size: 1.75rem; */
	/* font-weight: bold; */
}

.buttonRow {
	display: flex;
	align-items: baseline;
	width: 70%;
	justify-content: space-between;
}

.buttonRow > * {
	width: 33%;
}

.share-form {
	display: flex;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transform: translateX(-10px);
}
.icon {
	/* align-items: flex-start; */
	padding-right: 10px;
}

.icon:hover {
	cursor: pointer;
	transform: scale(0.95);
	transition: all 200ms linear;
}
