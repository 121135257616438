.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.backButton {
	/* border: 1px solid black; */
	width: 100%;
	text-align: left;
	margin-left: 20px;
}

.pageTitle {
	font-size: 1.75rem;
	font-weight: bold;
}

.keyValueItems {
	/* border: 1px solid pink; */
	padding: 1rem;
	width: 100%;
}
