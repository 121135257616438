.ItemBox{
    /* text-align: center; */
    font-family: Inter, sans-serif;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content:baseline;

    /* border: 1px solid black; */
}

.ItemBox > * {
    text-align: left;
    font-size: .75rem;
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid pink; */
    margin: 0;
}

.text{
    color: black;
    /* align-self: flex-start; */
}

.subtext{
    color: lightslategray;
    /* font-weight: bold; */
}

.text-area {
    grid-area: text;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}
.icon-area {
    grid-area: icon;
}
.image-area {
    grid-area: image;
}

.text-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "text";
}

.image-text-grid {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "image text";
}

.text-icon-grid {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "text icon";
}

.image-text-icon-grid {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "image text icon";
}