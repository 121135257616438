.main{
    font-family: Inter, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle{
    font-size: 1.75rem;
    font-weight: bold;
}

.buttonRow{
    display: flex;
    align-items:baseline;
    width: 70%;
    justify-content: space-between;
}

.buttonRow > *{
    width: 33%;
}

.subtitle{
    margin: 20px 0 20px;
    font-weight: bold;
}

.keyValueItems{
    /* border: 1px solid pink; */
    padding: 1rem;
    width: 100%;
}