@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.nav-container {
    height: 36px;
}

.burger-container {
    position: absolute;
    width: 36px;
    height: 36px;
    /* left: 30px; */
    left: 2.5rem;
    top: 2rem;
}

.avatar-container {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 2rem;
    top: 1.8rem;
}

.back-container {
    position: absolute;
    width: 36px;
    height: 36px;
    /* left: 30px; */
    left: 1.5rem;
    top: 2rem;
}

.dropdown-fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.dropdown-fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}
  
.dropdown-content {
    position: relative;
    background-color: #fdfdfd;
    top: 2rem;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    max-width: 300px;
}

.content-box {
    height: 300px;
    padding: 20px;
    text-align: center;
}
  
.middle {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container {
    height: 500px;
    position: relative;

}

.container {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-color: black;
    border-radius: 10px;
}
.main{
    font-family: 'Inter', sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle{
    /* font-size: 1.75rem; */
    /* font-weight: bold; */
}

.buttonRow{
    display: flex;
    align-items:baseline;
    width: 70%;
    justify-content: space-between;
}

.buttonRow > *{
    width: 33%;
}

.subtitle{
    margin: 20px 0 20px;
    font-size: 30px;
    font-family: 'Nunito';
    transform: translateY(-4px)
}

.listItems {
    /* border: 1px solid pink; */
    padding: 1rem;
    width: 100%;
    text-align: left;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateX(-10px);
}
.icon {
    /* align-items: flex-start; */
    padding-right: 10px;
}
.button {
	text-align: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	/* width: 5rem; */
	transition: all 100ms linear;
}
.button:hover {
	cursor: pointer;
	transform: scale(0.95);
	transition: all 200ms linear;
}

.svg {
	width: 35px;
}

.labelText {
	font-family: Inter, sans-serif;
	font-weight: bold;
	font-size: x-small;
	display: table-caption;
	/* line-height: 1.2rem; */
	margin: 0;
	padding-top: 0.5rem;
}

.ItemBox{
    /* text-align: center; */
    font-family: Inter, sans-serif;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content:baseline;

    /* border: 1px solid black; */
}

.ItemBox > * {
    text-align: left;
    font-size: .75rem;
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid pink; */
    margin: 0;
}

.text{
    color: black;
    /* align-self: flex-start; */
}

.subtext{
    color: lightslategray;
    /* font-weight: bold; */
}

.text-area {
    grid-area: text;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}
.icon-area {
    grid-area: icon;
}
.image-area {
    grid-area: image;
}

.text-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "text";
}

.image-text-grid {
    display: grid;
    grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "image text";
}

.text-icon-grid {
    display: grid;
    grid-template-columns: 1fr -webkit-min-content;
    grid-template-columns: 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "text icon";
}

.image-text-icon-grid {
    display: grid;
    grid-template-columns: -webkit-min-content 1fr -webkit-min-content;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: auto;
    grid-template-areas: "image text icon";
}
.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.backButton {
	/* border: 1px solid black; */
	width: 100%;
	text-align: left;
	margin-left: 20px;
}

.pageTitle {
	font-size: 1.75rem;
	font-weight: bold;
}

.keyValueItems {
	/* border: 1px solid pink; */
	padding: 1rem;
	width: 100%;
}

.KVItemBox{
    /* text-align: center; */
    font-family: Inter, sans-serif;
    box-sizing: border-box;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content:baseline;

    /* border: 1px solid black; */
}

.KVItemBox > * {
    text-align: left;
    font-size: .75rem;
    box-sizing: border-box;
    width: 50%;
    /* border: 1px solid pink; */
    margin: 0;
}

.keyName{
    color: lightslategray;
    /* align-self: flex-start; */
}

.value{
    font-weight: bold;
}
.main{
    font-family: Inter, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle{
    font-size: 1.75rem;
    font-weight: bold;
}

.buttonRow{
    display: flex;
    align-items:baseline;
    width: 70%;
    justify-content: space-between;
}

.buttonRow > *{
    width: 33%;
}

.subtitle{
    margin: 20px 0 20px;
    font-weight: bold;
}

.keyValueItems{
    /* border: 1px solid pink; */
    padding: 1rem;
    width: 100%;
}
.main{
    font-family: Inter, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle{
    /* font-size: 1.75rem; */
    /* font-weight: bold; */
}

.buttonRow{
    display: flex;
    align-items:baseline;
    width: 70%;
    justify-content: space-between;
}

.buttonRow > *{
    width: 33%;
}

.subtitle{
    margin: 20px 0 20px;
    font-size: 30px;
    font-family: 'Nunito';
    transform: translateY(-4px)
}

.listItems {
    /* border: 1px solid pink; */
    padding: 1rem;
    width: 100%;
    text-align: left;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateX(-10px);
}
.icon {
    /* align-items: flex-start; */
    padding-right: 10px;
}
.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.pageTitle {
	font-size: 1.75rem;
	font-weight: bold;
}

.recordItems {
	text-align: left;
	/* border: 1px solid pink; */
	padding: 1rem;
	margin-top: 1rem;
	width: 100%;
}

.items {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.items > * {
	box-sizing: border-box;
	/* border: 1px solid pink; */
}

.avatar {
	width: 10%;
	align-self: flex-start;
	text-align: center;
}

.avatar img {
	width: 100%;
	max-width: 2rem;
	border-radius: 100%;
}

.content {
	width: 80%;
	padding: 0 1rem;
}

.title {
	font-family: Nunito, sans-serif;
	font-weight: bold;
	font-size: 0.7rem;
}

.updated {
	color: #888;
	font-size: 0.7rem;
	margin: 0;
}

.button {
	width: 10%;
}

.main {
	font-family: Inter, sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.pageTitle {
	/* font-size: 1.75rem; */
	/* font-weight: bold; */
}

.buttonRow {
	display: flex;
	align-items: baseline;
	width: 70%;
	justify-content: space-between;
}

.buttonRow > * {
	width: 33%;
}

.share-form {
	display: flex;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transform: translateX(-10px);
}
.icon {
	/* align-items: flex-start; */
	padding-right: 10px;
}

.icon:hover {
	cursor: pointer;
	transform: scale(0.95);
	transition: all 200ms linear;
}

input {
	outline: none;
}

.wrapper {
	max-width: 500px;
	background: #fff;
	padding: 1rem;
}

.wrapper .input-data {
	height: 40px;
	width: 100%;
	position: relative;
}

.wrapper .input-data input {
	height: 100%;
	width: 100%;
	border: none;
	font-size: 17px;
	border-bottom: 2px solid silver;
}

.input-data input:not(:-ms-input-placeholder) ~ label {
	transform: translateX(100px);
	font-size: 15px;
	visibility: hidden;
	opacity: 0;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.input-data input:focus ~ label,
.input-data input:not(:placeholder-shown) ~ label {
	transform: translateX(100px);
	font-size: 15px;
	visibility: hidden;
	opacity: 0;
	transition: all 0.3s ease;
}

.wrapper .input-data label {
	position: absolute;
	bottom: 4px;
	left: 0;
	color: grey;
	pointer-events: none;
	visibility: visible;
	opacity: 1;
	transition: all 0.3s ease;
}

.input-data .underline {
	position: absolute;
	height: 2px;
	width: 100%;
	bottom: 0;
	text-align: left;
}

.input-data .underline:before {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: #0d5eaf;
	transform: scaleX(0);
	transform-origin: center;
	transition: transform 300ms ease;
}

.input-data input:not(:-ms-input-placeholder) ~ .underline:before {
	transform: scaleX(1);
}

.input-data input:focus ~ .underline:before,
.input-data input:not(:placeholder-shown) ~ .underline:before {
	transform: scaleX(1);
}

/* Hacky but needed */
input::-webkit-input-placeholder {
	color: white;
}
input:-ms-input-placeholder {
	color: white;
}
input::placeholder {
	color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

